.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container-fluid {
  width: 100%;
  margin: 70px 0px 70px 0px;
}
/* Phones */
@media (max-width: 570px) {
  .sliderimage {
    width: 80%;
    height: 400px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin-top: -28px;
  }
  .log {
    margin-top: 3px;
  }
}
/* styles for tablets */
@media (min-width: 768px) {
  .navbar {
    display: none;
  }
  .desktop-nav {
    display: block;
  }
  .small-sm {
    height: 500px !important;
    padding-top: 150px;
    width: 100%; /* Set the desired width of the container */
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: -40px;
    justify-content: center;
  }
  .carousel-container {
    height: 500px; /*  Set the desired height here */
  }
  .sliderimage {
    object-fit: cover !important;
    width: 100%;
    object-position: top;
    max-height: 500px;
  }
}
@media (max-width: 767px) {
  .navbar {
    display: block;
  }
  .desktop-nav {
    display: none;
  }
  .small-sm {
    height: 400px !important;
    padding-top: 50px;
  }
  .carousel-container {
    height: 100%; /* Set the desired height here */
  }
  .sliderimage {
    object-fit: contain; /* or object-fit: cover; */
    width: 100%;
    height: 100%;
  }
}
