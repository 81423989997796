.co_img {
  height: 250px;
  width: 300px;
  /* background-color: red; */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tech_div {
  border-radius: 10px;
  /* background-color: whitesmoke; */
  width: 300px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.course_cont {
  margin: 10px;
}

@media only screen and (max-width: 1213px) {
  .tech_row {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .adjust-top {
    margin-top: -40px;
  }
  .tech_div {
    padding-bottom: 20px;
    border-radius: 10px;
    /* background-color: whitesmoke; */
    width: 100%;
    margin-top: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .co_img {
    height: 250px;
    width: 100%;
    /* background-color: red; */
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .sliderimage-non {
    width: 100%;
    padding-top: 70px !important;
    height: 550px !important;
  }
}

@media (min-width: 768px) {
  .sliderimage-non {
    padding-top: 100px;
    width: 100%;
    height: 500px;
  }
  .adjust-top {
    margin-top: -70px;
  }
}
.img-cover {
  object-fit: contain !important;
  width: 100%;
  object-position: top;
  max-height: 500px;
}
