.QuickLinkLogo {
  position: absolute;
  left: 11.88%;
  top: 12.5%;
}

.EnrollBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.TrainedCompanyLogo {
  position: absolute;
  left: 50%;
  top: 12.5%;
  bottom: 9.37%;
}

.PostThumbnail {
  height: 150px;
  width: 300px;
}

.PostThumbnailSide {
  height: 100px;
  width: 150px;
}

.PostImage {
  height: 300px;
  width: 80%;
}

.QuickLinkText {
  /* Heading */
  left: 11.88%;
  top: 42.5%;
  right: 5%;
  position: absolute;
  bottom: 25%;
  padding: 4px 4px 4px 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: 20px;

  line-height: 28px;
  /* identical to box height, or 140% */

  letter-spacing: -0.02em;

  /* Neutral/800 */

  color: #1f2937;

  /* Inside auto layout */
}

/* Phones */
@media (max-width: 576px) {
  .tiles {
    margin-bottom: 20px !important;
  }
  .QuickLinkLogo {
    display: flex;
    left: 11.88%;
    top: 12.5%;
    bottom: 9.37%;
  }
  .QuickLinkText {
    /* Heading */
    display: flex;
    bottom: 40%;
    height: 28px;
    padding-left: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    letter-spacing: -0.02em;

    /* Neutral/800 */

    color: #1f2937;

    /* Inside auto layout */
  }
  .shift-right {
    width: 80%;
    margin-left: 10%;
  }
  .top-mobile {
    padding-top: 10px;
  }
  .lg-screen {
    width: 100%;
    margin-top: -80px !important;
  }
}
@media (min-width: 768px) {
  .lg-screen {
    width: 100%;
    margin-top: 150px !important;
    height: 450px !important;
    padding: 0px 10px 0px 0px;
  }
  .tiles {
    height: 200px;
  }
  .bg-grey {
    background-color: rgb(244, 244, 244);
  }
}
.news-img {
  height: 400px;
}
